import { useRef, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { DATE_MM_DD_YYYY_PATTERN } from '../constants';
import { InputField } from '../../components/InputField';
import { isFuture } from 'date-fns';
import { API_URL } from '../../_config';
import { ApiSearchBox } from '../../components/ApiSearchbox';
import { ReactJSXElementAttributesProperty } from '@emotion/react/types/jsx-namespace';

interface IncomingProps {
  setAddedPassport: (newState: string) => void;
}

interface PassportFormData {
  CountryId: string;
  ExpiryDate: string;
  Passport: string;
}

export const useAddPassportModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onAddPassportRef = useRef<(data: PassportFormData) => void>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm();
  const passportWatch = watch('Passport');
  const expDateWatch = watch('ExpiryDate');

  const handleReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const onSubmitPassport = (data: unknown) => {
    if (onAddPassportRef.current) {
      onAddPassportRef.current(data as PassportFormData);
      reset({CountryId: '', Passport: '', ExpiryDate: ''});
      setIsOpen(false);
      onAddPassportRef.current = undefined;
    }
  };

  const closeModal = () => {
    reset({CountryId: '', Passport: '', ExpiryDate: ''});
    setIsOpen(false);
    onAddPassportRef.current = undefined;
  };

  const modalElement = (
    <Modal centered show={isOpen}>
      <form onSubmit={handleSubmit(onSubmitPassport)}>
        <Modal.Body>
          <h4>Add Passport</h4>
          <Row>
            <Col xs={12}>
              <fieldset
                className={'input' + (errors?.CountryId ? ' invalid' : '')}
              >
                <legend>Select country</legend>
                <Controller
                  name={'CountryId'}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <ApiSearchBox
                        {...field}
                        mode="select"
                        endpointUrl={API_URL.COMMON.GET_COUNTRIES}
                        parserFunction={(data) => {
                          return (data as Record<string, string>[]).map(
                            (el) => {
                              return {
                                label: el.xql_name,
                                value: el.xql_countryid,
                              };
                            }
                          );
                        }}
                      />
                    );
                  }}
                />
              </fieldset>
            </Col>
            <Col sm={12}>
              <InputField
                id="Passport"
                label="Passport Number"
                required={!!expDateWatch}
                register={register}
                errors={errors}
              />
            </Col>
            <Col sm={12}>
              <InputField
                id="ExpiryDate"
                readOnly={!passportWatch}
                required={!!passportWatch}
                type="date"
                placeholder="YYYY-MM-DD"
                label="Expiry Date"
                register={register}
                errors={errors}
                registerParams={{
                  validate: {
                    isFuture: (value: string) =>
                      passportWatch ? isFuture(new Date(value)) : true,
                  },
                  pattern: {
                    value: DATE_MM_DD_YYYY_PATTERN,
                    message: 'Date must be in YYYY-MM-DD format',
                  },
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-success">
            Add Passport
          </button>
          <button type="submit" className="btn " onClick={closeModal}>
            Cancel
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );

  return {
    modalElement,
    show: (onAddPassport: (data: PassportFormData) => void) => {
      onAddPassportRef.current = onAddPassport;
      setIsOpen(true);
    },
    hide: closeModal,
    handleReload: handleReload
  };
};
