import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import StepIndicators from './stepIndicators';
import ToggleSwitch from '../blocks/toggleSwitch';
import { useSignupApi } from '../../_common/hooks/signupApiHook';

interface incomingProps {
    onSubmitAddressForm?: any;
    tab?: any
}

export default function Step4({ onSubmitAddressForm, tab }: incomingProps) {
    const signupApi = useSignupApi()
    const commonApi = useCommonApi()
    const navigate = useNavigate()
    const { showLoader, hideLoader } = useAppLoader()

    const [geoService, setGeoService] = useState<any>(null)
    const [shareLocationToggle, setShareLocationToggle] = useState<boolean>(false)
    const [longitude, setLongitude] = useState<any>(null)
    const [latitude, setLatitude] = useState<any>(null)
    const [profileId, setProfileId] = useState<any>(null)

    let ProfileId: any = sessionStorage.getItem("ProfileId")

    const goBack = () => {
        navigate(-1);
    }

    const getGeoService = () => {
        showLoader()
        commonApi.getGeoService({},
            (message: string, resp: any) => {
                hideLoader()
                setGeoService(resp)
            }, (message: string) => {
                hideLoader()
                // console.log(message);
            })
    }

    const onSubmit = (data: any) => {
        let params = {
            Longitude: shareLocationToggle ? `${longitude}` : "",
            Latitude: shareLocationToggle ? `${latitude}` : "",
            ShareLocation: shareLocationToggle
        }
        console.log({ data })

        showLoader();
        signupApi.createProfile(params, (message: string, resp: any) => {

            hideLoader();
            toast.success(message)
            navigate('/login')
            sessionStorage.removeItem("ProfileId")
        }, (message: string) => {
            toast.error(message)
            hideLoader();
        });
    }

    useEffect(() => {
        getGeoService()
    }, [])

    useEffect(() => {
        if (shareLocationToggle) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setLatitude(position.coords.latitude)
                setLongitude(position.coords.longitude)
            });
        }

    }, [shareLocationToggle])


    useEffect(() => {
        if (ProfileId == 'undefined') {
            setProfileId(null)
        } else {
            setProfileId(JSON.parse(ProfileId))
        }
    }, [ProfileId])

    return (
        <React.Fragment>
            <h1>Create Profile</h1>
            <StepIndicators step={4} />
            <h2>How would you like to register your services?</h2>
            <div className="d-flex justify-content-between">
                <h3>Use geo-location services:</h3>
                <div>
                    <div className="form-check form-switch">
                        <ToggleSwitch condition={shareLocationToggle} setCondition={setShareLocationToggle} />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                    </div>
                </div>
            </div>
            <div>
                <p>{geoService ? geoService[0]["xql_geoservicesinformation"] : null}</p>
            </div>
            <div className="mt-4 d-flex gap-2 login-btns w-100">
                <Button variant='success' className="w-100" onClick={onSubmit}>Accept</Button>
                <Button onClick={() => goBack()} className="btn btn-transparent w-100" variant="secondary">Back</Button>
            </div>
        </React.Fragment>
    )
}