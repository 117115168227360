import React from 'react';
import "../../styles/components/travels/singleTravel.scss";
import { CgProfile } from 'react-icons/cg'
import { BsThreeDotsVertical } from 'react-icons/bs';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { FLAGS_CDN_URL } from '../../_common/constants';
import { css } from '@emotion/css';
interface incomingProps {
    data: any
}

export default function TravelNotification({ data }: incomingProps) {

    const navigate = useNavigate()

    return (
        <React.Fragment>
            <div className='main--div'
                onClick={() => navigate(`/travels-details/${data?.xql_travelid}`)}
                style={{ cursor: 'pointer' }}
            >
                <div className='heading'>
                    <div className='heading-left'>
                        <CgProfile className='usericon' />
                        <div className="title">
                            <p className='name'>{data?.["_xql_countryid_value@OData.Community.Display.V1.FormattedValue"]}</p>
                            <p className='location'>{data?.["_xql_cityid_value@OData.Community.Display.V1.FormattedValue"]}</p>
                        </div>
                    </div>
                    <div className='heading-right'>
                        <BsThreeDotsVertical />
                    </div>
                </div>
                <div className={css({ display: "flex", justifyContent: 'center' })}>
                    {
                        data?.ISOCode ?
                            <img className='image' src={`${FLAGS_CDN_URL}/w320/${data?.ISOCode.toLowerCase()}.png`} alt='' /> :
                            <img src="/assets/img/logo.svg" max-width="100%" max-height="100%" alt=""></img>

                    }
                </div>

                <br />
                <div className="description">
                    <div >
                        <p className="description-head">Departure: {moment(data?.xql_departuredate).format('DD/MM/YYYY')}</p>
                        <p className="location">Return: {moment(data?.xql_returndate).format('DD/MM/YYYY')}</p>
                        <p className="pera">Reason: {data?.["xql_travelreason@OData.Community.Display.V1.FormattedValue"]}</p>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}