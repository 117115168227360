import {select } from 'redux-saga/effects'
import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL } from '../../_config'
import { CallApi } from './api/callApi';

export function* createStep1(action: any): any {
    try {
        yield put({
            type: ACTIONS.SIGNUP.CREATE_STEP1,
            payload: action.payload
        });

    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }

}

export function* createStep2(action: any): any {
    try {
        yield put({
            type: ACTIONS.SIGNUP.CREATE_STEP2,
            payload: action.payload
        });

    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }

}

export function* createStep3(action: any): any {
    try {
        yield put({
            type: ACTIONS.SIGNUP.CREATE_STEP3,
            payload: action.payload
        });

    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* resetSignup(action: any): any {
    try {
        yield put({
            type: ACTIONS.SIGNUP.RESET,
        });

    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }

}

export function* createProfile(action: any): any {
    try {
       const items =  yield select(state => state.signup)

       console.log('🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢', items)
        const data = {
            ...items,
            ...action.payload
        };

        console.log('🟣🟣🟣🟣🟣🟣🟣🟣🟣🟣🟣🟣', data)
        const resp = yield call(CallApi.POST, API_URL.SIGNUP.CREATE_PROFILE, data, false);
        if (resp.status >= 200 && resp.status < 300) {
            yield put({
                type: ACTIONS.SIGNUP.CREATE_PROFILE,
                payload: data,
            })

            action && action.callbackSuccess && action.callbackSuccess(resp.data);

        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }

    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }

}