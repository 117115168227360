export const API_ROOT_URL = process.env.REACT_APP_API_ROOT_URL;
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const STORAGE = 'dirco';
export const APP_URL = window.location.origin;

export const REFRESH_TOKEN_STORAGE = '__REFRESH_TOKEN';
export const ACCESS_TOKEN_STORAGE = '__ACCESS_TOKEN';
export const CONTACT_ID_STORAGE = '__CONTACT_ID';
export const USER_ID_STORAGE = '__USER_ID';

export const URLS = {
  HOME: '/',
  WELCOME: '/welcome',
  LOGIN: '/login',
  SIGNUP: '/signup',
  DASHBOARD: '/dashboard',
  TRAVELS: '/travels',
  TRAVEL_DETAILS: '/travels-details/:id',
  TRAVEL_CITY: '/travels-city',
  TRAVEL_CITY_INFO: '/travels-city-info',
  PRIVACY_POLICY: '/privacy-policy',
  NEW_TRAVEL: '/new-travel',
  CONNECT: '/connect',
  CONNECTED: '/connected',
  CONNECT_REQUEST: '/connect-request',
  CONNECTED_PROFILE: '/connected/:id',
  PROFILE: '/profile',
  PROFILE_SETTINGS: '/profile-settings',
  PROFILE_DETAILS: '/profile-details',
  KIN_DETAILS: '/kin-details',
  LOCATION_SETTINGS: '/location-settings',
  CASES: '/cases',
  CASE_DETAILS: '/case/:id',
  CREATE_CASE: '/create-case',
  ADVISORY: '/advisory',
  NOTIFICATIONS: '/notifications',
  NOTIFICATION_DETAILS: '/notification/:id',
  COUNTRY_DIRECTORY:'/country-directory',
  PUPOLAR_COUTRIES:'/popular-countries',
  POPULAR_CITIES:'/popular-cities',
  COUNTRY_INFO:'/country-info/:id',
  CITY_INFO:'/city-info/:id',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:id',
  REGISTRATION_SUCCESSFUL: '/registration-successful',
}


export const API_URL = {
  COMMON: {
    API: `${API_BASE_URL}`,
    GET_COUNTRIES: `${API_BASE_URL}/countries`,
    GET_CITIES: `${API_BASE_URL}/cities`,
    RETRIEVE_RELATIONSHIP: `${API_BASE_URL}/retrieve-optionsets`,
    RETRIEVE_GENDER: `${API_BASE_URL}/retrieve-optionsets`,
    GET_GEO_SERVICE: `${API_BASE_URL}/privacy-policy`,
    RETRIEVE_TRAVEL_REASON: `${API_BASE_URL}/retrieve-optionsets`,
    RETRIEVE_LODGE_TYPE: `${API_BASE_URL}/retrieve-optionsets`,
    GET_CITY_ALERT: `${API_BASE_URL}/city-alerts`,
    COUNTRY_INFO: `${API_BASE_URL}/country-details`,
    CITY_INFO: `${API_BASE_URL}/city-details`,
    APP_PASSPORT: `${API_BASE_URL}/create-passport`,
  },
  USER: {
    LOGIN: `${API_BASE_URL}/citizen-login`,
    REFRESH_TOKEN: `${API_BASE_URL}/refresh-token`,
    PRIVACY_POLICY: `${API_BASE_URL}/privacy-policy`,
    ME: `${API_BASE_URL}/details`,
    REGISTER: {
      STEP1: `${API_BASE_URL}/register-citizen`,
      STEP2: `${API_BASE_URL}/update-citizen`,
      STEP3: `${API_BASE_URL}/update-citizen`,
      STEP4: `${API_BASE_URL}/update-citizen`,
    },
    RETRIEVE_PROFILE: `${API_BASE_URL}/citizen-information`,
    FORGOT_PASSWORD: `${API_BASE_URL}/forgot-password`,
    RETRIEVE_PASSPORTS: `${API_BASE_URL}/citizen-passports`,
    UPDATE_PROFILE: `${API_BASE_URL}/update-profile`,
    RESET_PASSWORD: `${API_BASE_URL}/reset-password`,
  },
  CASES: {
    GET_CASES: `${API_BASE_URL}/citizen-cases`,
    CASE_DETAILS: `${API_BASE_URL}/case-details`,
    GET_CASES_TYPES: `${API_BASE_URL}/case-types`,
    CREATE_CASE: `${API_BASE_URL}/create-case`,
    CREATE_CASE_STEP2: `${API_BASE_URL}/create-case`,
    CREATE_CASE_DOC_UPLOAD: `${API_BASE_URL}/upload-case-attachments`,
  },
  TRAVELS: {
    GET_TRAVELS: `${API_BASE_URL}/travels`,
    TRAVELS_DETAILS: `${API_BASE_URL}/travel-details`,
    STEP1: `${API_BASE_URL}/create-travel`,
    STEP2: `${API_BASE_URL}/update-citizen`,
    STEP3: `${API_BASE_URL}/create-travel`,
    STEP4: `${API_BASE_URL}/create-travel`,
  },
  ADVISORY: {
    CREATE_ALERT: `${API_BASE_URL}/create-alert`,
    GET_CONTACT_ALERT: `${API_BASE_URL}/alerts`,
    RETRIEVE_ALERT_TYPE: `${API_BASE_URL}/retrieve-optionsets`,
    GET_ALERT_BY_TYPE: `${API_BASE_URL}/alert-types`,
  },
  COUNTRY_DIRECTORY: {
    POPULAR_COUNTRIES: `${API_BASE_URL}/popular-countries`,
    POPULAR_CITIES: `${API_BASE_URL}/popular-cities`,
  },
  SIGNUP:{
    CREATE_PROFILE: `${API_BASE_URL}/register-citizen`
  }
}

export const ACTIONS = {
  LOADER: {
    SET_FP_STATUS: 'ACTIONS/LOADER/SET_FP_STATUS',
  },
  COMMON: {
    POST: 'ACTIONS/COMMON/POST',
    GET: 'ACTIONS/COMMON/GET',
    GET_COUNTRIES: 'ACTIONS/COMMON/GET_COUNTRIES',
    GET_CITIES: 'ACTIONS/COMMON/GET_CITIES',
    RETRIEVE_RELATIONSHIP: 'ACTIONS/COMMON/RETRIEVE_RELATIONSHIP',
    RETRIEVE_GENDER: 'ACTIONS/COMMON/RETRIEVE_GENDER',
    GET_GEO_SERVICE: 'ACTIONS/COMMON/GET_GEO_SERVICE',
    RETRIEVE_TRAVEL_REASON: 'ACTIONS/COMMON/RETRIEVE_TRAVEL_REASON',
    RETRIEVE_LODGE_TYPE: 'ACTIONS/COMMON/RETRIEVE_LODGE_TYPE',
    GET_CITY_ALERT: 'ACTIONS/COMMON/GET_CITY_ALERT',
    COUNTRY_INFO:'ACTIONS/COMMON/COUNTRY_INFO',
    CITY_INFO:'ACTIONS/COMMON/CITY_INFO',
    ADD_PASSPORT:'ACTIONS/COMMON/ADD_PASSPORT'
  },
  USER: {
    LOGIN: 'ACTIONS/USER/LOGIN',
    UPDATE_TOKEN: 'ACTIONS/USER/UPDATE_TOKEN',
    LOGOUT: 'ACTIONS/USER/LOGOUT',
    PRIVACY_POLICY: 'ACTIONS/USER/PRIVACY_POLICY',
    ME: 'ACTIONS/USER/ME',
    REGISTER: {
      STEP1: 'ACTIONS/USER/REGISTER/STEP1',
      STEP2: 'ACTIONS/USER/REGISTER/STEP2',
      STEP3: 'ACTIONS/USER/REGISTER/STEP3',
      STEP4: 'ACTIONS/USER/REGISTER/STEP4',
    },
    RETRIEVE_PROFILE: 'ACTIONS/USER/RETRIEVE_PROFILE',
    RETRIEVE_PASSPORTS: 'ACTIONS/USER/RETRIEVE_PASSPORTS',
    UPDATE_PROFILE: 'ACTIONS/USER/UPDATE_PROFILE',
    FORGOT_PASSWORD: 'ACTIONS/USER/FORGOT_PASSWORD',
    RESET_PASSWORD: 'ACTIONS/USER/RESET_PASSWORD',
  },
  CASES: {
    GET_CASES: 'ACTIONS/CASES/GET_CASES',
    CASE_DETAILS: 'ACTIONS/CASES/CASE_DETAILS',
    GET_CASES_TYPES: 'ACTIONS/CASES/GET_CASES_TYPES',
    CREATE_CASE: 'ACTIONS/CASES/CREATE_CASE',
    CREATE_CASE_STEP2: 'ACTIONS/CASES/CREATE_CASE_STEP2',
    CREATE_CASE_DOC_UPLOAD: 'ACTIONS/CASES/CREATE_CASE_DOC_UPLOAD',
  },
  TRAVELS: {
    GET_TRAVELS: 'ACTIONS/TRAVELS/GET_TRAVELS',
    TRAVELS_DETAILS: 'ACTIONS/TRAVELS/TRAVELS_DETAILS',
    STEP1: 'ACTIONS/TRAVELS/STEP1',
    STEP2: 'ACTIONS/TRAVELS/STEP2',
    STEP3: 'ACTIONS/TRAVELS/STEP3',
    STEP4: 'ACTIONS/TRAVELS/STEP4',
  },
  ADVISORY: {
    CREATE_ALERT: 'ACTIONS/ADVISORY/CREATE_ALERT',
    GET_CONTACT_ALERT: 'ACTIONS/ADVISORY/GET_CONTACT_ALERT',
    RETRIEVE_ALERT_TYPE: 'ACTIONS/ADVISORY/RETRIEVE_ALERT_TYPE',
    GET_ALERT_BY_TYPE: 'ACTIONS/ADVISORY/GET_ALERT_BY_TYPE',
  },
  COUNTRY_DIRECTORY: {
    POPULAR_COUNTRIES: 'ACTIONS/COUNTRY_DIRECTORY/POPULAR_COUNTRIES',
    POPULAR_CITIES: 'ACTIONS/COUNTRY_DIRECTORY/POPULAR_CITIES',
  },
  SIGNUP: {
    CREATE_STEP1: 'ACTIONS/SIGNUP/CREATE_STEP1',
    CREATE_STEP2: 'ACTIONS/SIGNUP/CREATE_STEP2',
    CREATE_STEP3: 'ACTIONS/SIGNUP/CREATE_STEP3',
    CREATE_STEP4: 'ACTIONS/SIGNUP/CREATE_STEP4',
    RESET:'ACTIONS/SIGNUP/RESET',
    CREATE_PROFILE: 'ACTIONS/SIGNUP/CREATE_PROFILE'
  }
}

export const SAGA_ACTIONS = {
  COMMON: {
    GENERIC_POST: 'SAGA_ACTIONS/COMMON/GENERIC_POST',
    GENERIC_GET: 'SAGA_ACTIONS/COMMON/GENERIC_GET',
    POST: 'SAGA_ACTIONS/COMMON/POST',
    GET: 'SAGA_ACTIONS/COMMON/GET',
    GET_COUNTRIES: 'SAGA_ACTIONS/COMMON/GET_COUNTRIES',
    GET_CITIES: 'SAGA_ACTIONS/COMMON/GET_CITIES',
    RETRIEVE_RELATIONSHIP: 'SAGA_ACTIONS/COMMON/RETRIEVE_RELATIONSHIP',
    RETRIEVE_GENDER: 'SAGA_ACTIONS/COMMON/RETRIEVE_GENDER',
    GET_GEO_SERVICE: 'SAGA_ACTIONS/COMMON/GET_GEO_SERVICE',
    RETRIEVE_TRAVEL_REASON: 'SAGA_ACTIONS/COMMON/RETRIEVE_TRAVEL_REASON',
    RETRIEVE_LODGE_TYPE: 'SAGA_ACTIONS/COMMON/RETRIEVE_LODGE_TYPE',
    GET_CITY_ALERT: 'SAGA_ACTIONS/COMMON/GET_CITY_ALERT',
    COUNTRY_INFO:'SAGA_ACTIONS/COMMON/COUNTRY_INFO',
    CITY_INFO:'SAGA_ACTIONS/COMMON/CITY_INFO',
    ADD_PASSPORT:'SAGA_ACTIONS/COMMON/ADD_PASSPORT',
  },
  USER: {
    LOGIN: 'SAGA_ACTIONS/USER/LOGIN',
    REFRESH_TOKEN: 'SAGA_ACTIONS/USER/REFRESH_TOKEN',
    LOGOUT: 'SAGA_ACTIONS/USER/LOGOUT',
    PRIVACY_POLICY: 'SAGA_ACTIONS/USER/PRIVACY_POLICY',
    ME: 'SAGA_ACTIONS/USER/ME',
    REGISTER: {
      STEP1: 'SAGA_ACTIONS/USER/REGISTER/STEP1',
      STEP2: 'SAGA_ACTIONS/USER/REGISTER/STEP2',
      STEP3: 'SAGA_ACTIONS/USER/REGISTER/STEP3',
      STEP4: 'SAGA_ACTIONS/USER/REGISTER/STEP4',
    },
    FORGOT_PASSOWORD: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD',
    RETRIEVE_PROFILE: 'SAGA_ACTIONS/USER/RETRIEVE_PROFILE',
    RETRIEVE_PASSPORTS: 'SAGA_ACTIONS/USER/RETRIEVE_PASSPORTS',
    UPDATE_PROFILE: 'SAGA_ACTIONS/USER/UPDATE_PROFILE',
    FORGOT_PASSWORD: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD',
    RESET_PASSWORD: 'SAGA_ACTIONS/USER/RESET_PASSWORD',
  },
  CASES: {
    GET_CASES: 'SAGA_ACTIONS/CASES/GET_CASES',
    CASE_DETAILS: 'SAGA_ACTIONS/CASES/CASE_DETAILS',
    GET_CASES_TYPES: 'SAGA_ACTIONS/CASES/GET_CASES_TYPES',
    CREATE_CASE: 'SAGA_ACTIONS/CASES/CREATE_CASE',
    CREATE_CASE_STEP2: 'SAGA_ACTIONS/CASES/CREATE_CASE_STEP2',
    CREATE_CASE_DOC_UPLOAD: 'SAGA_ACTIONS/CASES/CREATE_CASE_DOC_UPLOAD',
  },
  TRAVELS: {
    GET_TRAVELS: 'SAGA_ACTIONS/TRAVELS/GET_TRAVELS',
    TRAVELS_DETAILS: 'SAGA_ACTIONS/TRAVELS/TRAVELS_DETAILS',
    STEP1: 'SAGA_ACTIONS/TRAVELS/STEP1',
    STEP2: 'SAGA_ACTIONS/TRAVELS/STEP2',
    STEP3: 'SAGA_ACTIONS/TRAVELS/STEP3',
    STEP4: 'SAGA_ACTIONS/TRAVELS/STEP4',
  },
  ADVISORY: {
    CREATE_ALERT: 'SAGA_ACTIONS/ADVISORY/CREATE_ALERT',
    GET_CONTACT_ALERT: 'SAGA_ACTIONS/ADVISORY/GET_CONTACT_ALERT',
    RETRIEVE_ALERT_TYPE: 'SAGA_ACTIONS/ADVISORY/RETRIEVE_ALERT_TYPE',
    GET_ALERT_BY_TYPE: 'SAGA_ACTIONS/ADVISORY/GET_ALERT_BY_TYPE',
  },
  COUNTRY_DIRECTORY: {
    POPULAR_COUNTRIES: 'SAGA_ACTIONS/COUNTRY_DIRECTORY/POPULAR_COUNTRIES',
    POPULAR_CITIES: 'SAGA_ACTIONS/COUNTRY_DIRECTORY/POPULAR_CITIES',
  },
  SIGNUP: {
    CREATE_STEP1: 'SAGA_ACTIONS/SIGNUP/CREATE_STEP1',
    CREATE_STEP2: 'SAGA_ACTIONS/SIGNUP/CREATE_STEP2',
    CREATE_STEP3: 'SAGA_ACTIONS/SIGNUP/CREATE_STEP3',
    CREATE_STEP4: 'SAGA_ACTIONS/SIGNUP/CREATE_STEP4',
    RESET:'SAGA_ACTIONS/SIGNUP/RESET',
    CREATE_PROFILE: 'SAGA_ACTIONS/SIGNUP/CREATE_PROFILE'
  }
}

export const INPUT_MAX_LENGTHS = {
  default: 250,
}
