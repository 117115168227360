import { ActionExtended } from '../../../_common/interfaces/ActionExtended';
import { ACTIONS } from '../../../_config';

export type NextOfKinType = {
    FirstName: string;
    LastName: string;
    Email: string;
    DialCode: string;
    CellPhone: string;
    Address: string;
    CountryId: string;
    CityId: string;
    RelationshipType: number | null;
    OtherRelationship: string;
    Hospitalization: boolean;
    Death: boolean;
    Incarceration: boolean;
}

export interface SignupReducer {
    FirstName: string;
    LastName: string;
    IDNumber: string;
    DateOfBirth: string;
    Gender: number,
    DialCode: string;
    MobileNumber: string;
    Email: string;
    Password: string;
    Passport: string;
    ExpiryDate: string;
    NextOfKins: [NextOfKinType] | [];
    PermanentResidenceAbroad: boolean;
    CountryIdAbroad: string;
    CityIdAbroad: string;
    PassportAbroad: string;
    ExpiryDateAbroad: string;
    AddressAbroad: string;
    DialCodeAbroad: string;
    TelephoneAbroad: string;
    ShareLocation: boolean;
    Longitude: string;
    Latitude: string;
}

const initialState: SignupReducer = {
    FirstName: '',
    LastName: '',
    IDNumber: '',
    DateOfBirth: '',
    Gender: 0,
    DialCode: '',
    MobileNumber: '',
    Email: '',
    Password: '',
    Passport: '',
    ExpiryDate: '',
    NextOfKins: [],
    PermanentResidenceAbroad: false,
    CountryIdAbroad: '',
    CityIdAbroad: '',
    PassportAbroad: '',
    ExpiryDateAbroad: '',
    AddressAbroad: '',
    DialCodeAbroad: '',
    TelephoneAbroad: '',
    ShareLocation: true,
    Longitude: '',
    Latitude: ''
}

const SignupReducer = (state = initialState, action: ActionExtended) => {
    console.log('ACTION::::::', action)
    switch (action.type) {
        case ACTIONS.SIGNUP.CREATE_STEP1:
            return {
                ...state,
                FirstName: action.payload.FirstName,
                LastName: action.payload.LastName,
                IDNumber: action.payload.IDNumber,
                DateOfBirth: action.payload.DateOfBirth,
                Gender: action.payload.Gender,
                DialCode: action.payload.DialCode,
                MobileNumber: action.payload.MobileNumber,
                Email: action.payload.Email,
                Password: action.payload.Password,
                Passport: action.payload.Passport,
                ExpiryDate: action.payload.ExpiryDate,
            }
        case ACTIONS.SIGNUP.CREATE_STEP2:
            console.log(action)
            return {
                ...state,
                PermanentResidenceAbroad: action.payload.PermanentResidenceAbroad,
                CountryIdAbroad: action.payload.CountryIdAbroad,
                CityIdAbroad: action.payload.CityIdAbroad,
                PassportAbroad: action.payload.PassportAbroad,
                ExpiryDateAbroad: action.payload.ExpiryDateAbroad,
                AddressAbroad: action.payload.AddressAbroad,
                DialCodeAbroad: action.payload.DialCodeAbroad,
                TelephoneAbroad: action.payload.TelephoneAbroad,
                ShareLocation: action.payload.ShareLocation,
                Longitude: action.payload.Longitude,
                Latitude: action.payload.Latitude,
            }
        case ACTIONS.SIGNUP.CREATE_STEP3:
            console.log(action)
            return {
                ...state,
                NextOfKins: [
                    {
                        FirstName: action.payload.FirstName,
                        LastName: action.payload.LastName,
                        Email: action.payload.Email,
                        DialCode: action.payload.DialCode,
                        CellPhone: action.payload.CellPhone,
                        Address: action.payload.Address,
                        CountryId: action.payload.CountryId,
                        CityId: action.payload.CityId,
                        RelationshipType: action.payload.RelationshipType,
                        OtherRelationship: action.payload.OtherRelationship,
                        Hospitalization: action.payload.Hospitalization,
                        Death: action.payload.Death,
                        Incarceration: action.payload.Incarceration
                    }
                ]
            }
        case ACTIONS.SIGNUP.CREATE_STEP4:
            return {
                ...state,
                ShareLocation: action.payload.ShareLocation,
                Longitude: action.payload.Longitude,
                Latitude: action.payload.Latitude
            }
        case ACTIONS.SIGNUP.RESET:
            return {
                ...state,
                FirstName: '',
                LastName: '',
                IDNumber: '',
                DateOfBirth: '',
                Gender: '',
                DialCode: '',
                MobileNumber: '',
                Email: '',
                Password: '',
                Passport: '',
                ExpiryDate: '',
                NextOfKins: [],
                PermanentResidenceAbroad: false,
                CountryIdAbroad: '',
                CityIdAbroad: '',
                PassportAbroad: '',
                ExpiryDateAbroad: '',
                AddressAbroad: '',
                DialCodeAbroad: '',
                TelephoneAbroad: '',
                ShareLocation: true,
                Longitude: '',
                Latitude: ''

            }
        case ACTIONS.SIGNUP.CREATE_PROFILE:
            return {

            }
        default:
            return state;
    }

}

export default SignupReducer;