import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL } from '../../_config'
import { CallApi } from './api/callApi';
import qs from 'query-string';
 
const API_BASE_URL = process.env.REACT_APP_API_URL;

export function* genericPost(action: any): any {
    try {
        const { ___endpointUrl = '', ___body, ___query } = action.payload;
        const resp = yield call(CallApi.POST, `${___endpointUrl}?${qs.stringify(___query)}`, ___body);
        if (resp.status >= 200 && resp.status < 300  ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* genericGet(action: any): any {
    try {
        const { ___endpointUrl = '', ___body, ___query } = action.payload;
        const resp = yield call(CallApi.GET, `${___endpointUrl}?${qs.stringify(___query)}`, ___body);
        if (resp.status >= 200 && resp.status < 300  ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* post(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_BASE_URL+'/ExecuteRequest', data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.isSuccess ) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* get(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_BASE_URL+'/ExecuteRequest', data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.isSuccess  && resp.data.outputParameters) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCountries(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_COUNTRIES, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCities(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.GET_CITIES, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* retrieveRelationShip(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.RETRIEVE_RELATIONSHIP, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* retrieveGender(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.RETRIEVE_GENDER, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getGeoService(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_GEO_SERVICE, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* retrieveLodgeType(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.RETRIEVE_LODGE_TYPE, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            sessionStorage.setItem("TravelId", JSON.stringify(resp.data.TravelId));
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* retrieveTravelReason(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.RETRIEVE_TRAVEL_REASON, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCityAlert(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.GET_CITY_ALERT, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            yield put({
                type: ACTIONS.COMMON.GET_CITY_ALERT,
                payload: {
                    cityDetails:  resp.data,
                }
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCityInfo(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.CITY_INFO, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCountryInfo(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMMON.COUNTRY_INFO, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}


export function* addPassport(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMMON.APP_PASSPORT, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.IsSuccess) {
            sessionStorage.setItem("CaseId", JSON.stringify(resp.data.CaseId));
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}