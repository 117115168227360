import React, { useEffect, useMemo, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Select from '../blocks/Select';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import { useTravelsApi } from '../../_common/hooks/api/travelsApiHook';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import StepIndicators from './stepIndicators';
import ToggleSwitch from '../blocks/toggleSwitch';
import { useUserApi } from '../../_common/hooks/api/UserApiHook';
import { useUserSelector } from '../../_common/hooks/selectors/userSelector';
import moment from 'moment';
import { useAddPassportModal } from '../../_common/hooks/useAddPassportModal';
interface incomingProps {
    onSubmitAddressForm?: any;
    tab?: any
}

const ADD_OPTION = {
    value: "ADD_OPTION",
    label: "Add..."
}

const LOADING_OPTION = {value: '', label: 'Loading...'};

export default function Step1({ onSubmitAddressForm, tab }: incomingProps) {

    const { handleSubmit, setValue, formState: { errors }, control } = useForm()

    const commonApi = useCommonApi()
    const travelApi = useTravelsApi()
    const navigate = useNavigate()
    const { showLoader, hideLoader } = useAppLoader()
    const userApi = useUserApi()
    const user: any = useUserSelector()

    const [travelReason, setTravelReason] = useState<any>(null)
    const [countries, setCountries] = useState<any>(null)
    const [travelId, setTravelId] = useState<any>(null)
    const [passports, setPassports] = useState([])
    const [hasSouthAfricanPassport, setHasSouthAfricanPassport] = useState<boolean>(true)
    const [isSharingLocation, setIsSharingLocation] = useState<boolean>(true)
    const [hasTravelInsuarance, setHasTravelInsurance] = useState<boolean>(true)
    const [isPassportLoading, setIsPassportLoading] = useState<boolean>(false)
    let TravelId: any = sessionStorage.getItem('TravelId')

    const getPassports = () => {
        setIsPassportLoading(true)
        setPassports([]);
        userApi.getCitizenPassport({ contact: user.contactid },
            (message: string, resp: any) => {
                setIsPassportLoading(false)
                if (resp.IsSuccess) {
                    setPassports(resp.Passports.map((data: any) => {
                        data.value = data.PassportId
                        data.label = data.Passport
                        return data
                    }))
                }

            }, (message: string) => {
                setIsPassportLoading(false)
                console.log(message);
            }
        )
    }

    const retrieveTravelReason = () => {
        commonApi.retrieveTravelReason({ "DropdownName": "xql_travelreason" },
            (message: string, resp: any) => {
                let options: any = [];
                if (resp.Options && resp.Options.length > 0) {
                    resp.Options.map((item: any) => {
                        item.value = item.Value
                        item.label = item.Label.UserLocalizedLabel.Label
                        options.push(item)
                    })
                    setTravelReason(options)
                }
            }, (message: string) => {
                console.log(message);
            })
    }

    const getCountries = () => {
        commonApi.getCountries({},
            (message: string, resp: any) => {
                if (resp.length > 0) {
                    setCountries(resp.map((data: any) => {
                        data.label = data.xql_name;
                        data.value = data.xql_countryid;
                        return data;
                    }))
                }
            }, (message: string) => {
                console.log(message);
            })
    }

    const onSubmit = (data: any) => {
        showLoader()
        let params = {
            "EntityName": "TravelReason",
            "TravelId": travelId,
            "TravelReason": data.TravelReason.value,
            "TravelInsurance": hasTravelInsuarance,
            "TravelWithSAPassport": hasSouthAfricanPassport,
            "ShareLocation": isSharingLocation,
            "PassportId": data.PassportId,
        }

        showLoader();
        travelApi.createTravelStepFour(params, (message: string, resp: any) => {
            hideLoader();
            toast.success(message)
            navigate('/travels')
        }, (message: string) => {
            toast.error(message)
            hideLoader();
        });
    }

    useEffect(() => {
        retrieveTravelReason()
        getPassports()

    }, [])

    useEffect(() => {
        getCountries()
        getPassports()
    }, [])

    useEffect(() => {
        if (TravelId == 'undefined') {
            setTravelId(null)
        } else {
            setTravelId(JSON.parse(TravelId))
        }
    }, [TravelId]);

    const { show: showAddPassportModal, modalElement: addPassportModalElement, } = useAddPassportModal();

    const handleOnAddPassportOptionSelect = () => {
        setValue('PassportId', '');

        showAddPassportModal((passportData) => {
            showLoader();
            commonApi.addPassport({ ...passportData, ContactId: user?.contactid },
                (message: string, resp: any) => {
                    hideLoader()
                    toast.success(`${message}. Please resubmit your travel details.`)
                    // handleReload()
                    getPassports();
                }, (message: string) => {
                    hideLoader()
                })
        })
    }
 
    return (
        <React.Fragment>
            <h1>Register new travel</h1>
            <StepIndicators step={3} />
            <div className="d-flex justify-content-between mt-5">
                <h2>What is the primary reason for your travel?</h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="w-100" autoComplete='off'>
                <div className="container-fluid p-0">
                    <Row>
                        <Col sm={6}>
                            <fieldset className={"input" + (errors?.TravelReason ? ' invalid' : '')}>
                                <legend>Travel Reason</legend>
                                <Controller
                                    name={"TravelReason"}
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange, onBlur } }) => {
                                        return (<Select
                                            className={"select-box"}
                                            options={travelReason ? travelReason : []}
                                            placeholder={""}
                                            onChange={onChange}
                                            value={value}
                                            defaultValue={value}
                                        />)
                                    }}
                                />
                            </fieldset>
                        </Col>

                        <Col sm={6}>
                            <fieldset className={"input" + (errors?.PassportId ? ' invalid' : '')}>
                                <legend>Passport</legend>
                                <Controller
                                    name={"PassportId"}
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field: { value, onChange, onBlur } }) => {
                                        return (<Select
                                            className={"select-box"}
                                            options={isPassportLoading ? [LOADING_OPTION]  : passports ? [...passports, ADD_OPTION] : [ADD_OPTION]}
                                            placeholder={""}
                                            onChange={(e) => {
                                                const selectedValue = e.target.value;
                                                if (selectedValue === "ADD_OPTION") {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    handleOnAddPassportOptionSelect();
                                                    return false;
                                                } else {
                                                    onChange?.(e);
                                                }
                                            }}
                                            value={value}
                                            defaultValue={value}
                                        />)
                                    }}
                                />
                            </fieldset>
                        </Col>

                    </Row>
                    <Row >
                        <Col sm={6}>
                            <div className="d-flex justify-content-between align-items-center" >
                                <div>I have travel insuarance</div>
                                <div>
                                    <ToggleSwitch condition={hasTravelInsuarance} setCondition={setHasTravelInsurance} />
                                </div>
                            </div>
                        </Col>

                        <Col sm={6}>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>Share Location</div>
                                <div>
                                    <ToggleSwitch condition={isSharingLocation} setCondition={setIsSharingLocation} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="mt-4 d-flex gap-2 login-btns">
                    <Button className="btn" variant="success" type='submit'>Save & continue</Button>
                </div>
            </form>
            {addPassportModalElement}
        </React.Fragment>
    )
}