import { SAGA_ACTIONS } from '../../_config';
import { useApiCall } from './common/appApiCallHook';
import {
  SignupStep4Req,
} from '../interfaces/ApiReqRes'

export function useSignupApi() {
    const callApi = useApiCall()

      const createProfile = (data: SignupStep4Req, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.SIGNUP.CREATE_PROFILE, data, onSuccess, onError);
      }

      return {
        createProfile
      }
}