import { css } from '@emotion/css';
import { useApiCall } from '../_common/hooks/common/appApiCallHook';
import { SAGA_ACTIONS } from '../_config';
import React, { useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { BsCaretDownFill } from 'react-icons/bs';
import Select from './blocks/Select';

interface ApiSearchOption {
  label: string;
  value: string;
}

interface ApiSearchBoxProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  endpointUrl: string;
  endpointMethod?: 'GET' | 'POST';
  query?: Record<string | number, string | number>;
  body?: Record<string | number, string | number>;
  parserFunction: (data: unknown) => ApiSearchOption[];
  mode?: 'default' | 'select';
  defaultOptions?: ApiSearchOption[];
  defaultValue?: string | number;
}

const LOADING_OPTION = {value: '', label: 'Loading...'};

const CustomToggle = React.forwardRef<any, any>(
  ({ children, onClick }, ref) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href=""
      ref={ref}
      className={css({
        width: '100%',
        display: 'flex',
        color: 'unset',
        marginTop: -8,
        paddingTop: 8,
        paddingRight: 16,
        paddingLeft: 16,
        paddingBottom: 8,
        textDecoration: 'none',
      })}
      onClick={(e) => {
        e.preventDefault();
        onClick?.(e);
      }}
    >
      <div className={css({ flex: 1, fontSize: 14 })}>{children}</div>
      <div>
        <BsCaretDownFill color="#222" />
      </div>
    </a>
  )
);

const CustomMenu = React.forwardRef<any, any>(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">{React.Children.toArray(children)}</ul>
      </div>
    );
  }
);

function debounce(callback: Function, delay: number) {
  let timerId: ReturnType<typeof setTimeout>;

  return function (this: any, ...args: any[]) {
    const context = this;
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      callback.apply(context, args);
    }, delay);
  };
}

// inject current input value everywhere where {{value}} is found
const injectValueOnObjectValues = (
  object: Record<string | number, string | number>,
  replacementValue: string
) => {
  const entries = Object.entries({ ...object }).map(([key, value]) => {
    if (`${value}`.includes('{{value}}')) {
      return [key, `${value}`.replace('{{value}}', replacementValue)];
    } else {
      return [key, value];
    }
  });
  return Object.fromEntries(entries);
};

export const ApiSearchBox = ({
  onChange,
  name,
  id,
  endpointUrl,
  endpointMethod = 'GET',
  query = {},
  body = {},
  parserFunction,
  mode = 'default',
  defaultOptions = [],
  defaultValue,
  ...restOfProps
}: ApiSearchBoxProps) => {
  const callApi = useApiCall();

  const initialSearchCacheRef = useRef<ApiSearchOption[]>([]);

  const initialValueTrackRef = useRef(true);

  const menuIsOpenStatusRef = useRef<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedOption, setSelectedOption] = useState<ApiSearchOption>();

  const searchInputRef = useRef<HTMLInputElement>(null);

  const [options, setOptions] = useState<ApiSearchOption[]>(defaultOptions);

  const getData = (filterValue: string = '') => {
    const queryWithInjectedValues = injectValueOnObjectValues(
      query,
      filterValue
    );
    const bodyWithInjectedValues = injectValueOnObjectValues(body, filterValue);

    setIsLoading(true);
    callApi(
      SAGA_ACTIONS.COMMON[`GENERIC_${endpointMethod}`],
      {
        ___endpointUrl: endpointUrl,
        ___body: bodyWithInjectedValues,
        ___query: queryWithInjectedValues,
      },
      (message: string, resp: unknown) => {
        setIsLoading(false);
        const parsedData = parserFunction(resp);
        if (filterValue === '') {
          initialSearchCacheRef.current = parsedData;
        }
        const mergedOptions = [...defaultOptions, ...parsedData];
        setOptions(mergedOptions);
        const matchingOption = mergedOptions.find((option) => option.value === defaultValue)
        // console.log({matchingOption, defaultValue});
        if(matchingOption) {
          setSelectedOption(matchingOption);
        }
        if (searchInputRef.current && menuIsOpenStatusRef.current) {
          searchInputRef.current?.focus();
        }
      },
      (message: string, resp: unknown) => {
        setIsLoading(false);
        // console.error('⛔️', message, resp);
      }
    );
  };

  const handleOnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    getData(e.target.value);
  };

  useDeepCompareEffect(() => {
    if (!isLoading) {
      setSelectedOption(undefined);
      getData();
    }
  }, [body, query]);

  useEffect(() => {
    if (!initialValueTrackRef.current && selectedOption?.value) {
      onChange?.({
        target: {
          value: selectedOption?.value || '',
        },
        name: name!,
        id: id!,
      } as any);
      setOptions(initialSearchCacheRef.current);
    } else {
      initialValueTrackRef.current = false;
    }
  }, [selectedOption]);

  return mode === 'select' ? (
    <Select
      className={'select-box'}
      readOnly={isLoading}
      placeholder={isLoading ? 'Loading...' : 'Choose...'}  
      options={isLoading ? [LOADING_OPTION] : options as any} 
      onChange={onChange as any}
      {...restOfProps as any}
    />
  ) : (
    <Dropdown
      onSelect={(eventKey) => {
        setSelectedOption(options.find((option) => option.value === eventKey));
      }}
      onToggle={(isOpen) => {
        menuIsOpenStatusRef.current = isOpen;
        if (isOpen && searchInputRef.current) {
          searchInputRef.current.value = '';
          setTimeout(() => {
            searchInputRef.current?.focus();
          }, 500);
        }
      }}
    >
      <input
        type="hidden"
        name={name}
        id={id}
        value={selectedOption?.value}
        {...(restOfProps as any)}
      />
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom" disabled>
        {selectedOption?.label || 'Choose...'}
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={CustomMenu}
        className={css({ minWidth: '100%', zIndex: 1099 })}
      >
        <Dropdown.Header>
          <input
            type="text"
            ref={searchInputRef}
            placeholder="Search..."
            autoFocus
            className={css({ border: '1px solid #aaa !important' })}
            onChange={debounce(handleOnInputChange, 500)}
          />
        </Dropdown.Header>
        {isLoading ? (
          <Dropdown.Item disabled>Loading...</Dropdown.Item>
        ) : (
          options.length < 1 && (
            <Dropdown.Item disabled>No options</Dropdown.Item>
          )
        )}
        {!isLoading &&
          options.map((option) => (
            <Dropdown.Item eventKey={option.value}>
              {option.label}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
