import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'

import { Link, useNavigate } from 'react-router-dom';
import { useCommonApi } from '../../_common/hooks/api/common/commonApiHook';
import StepIndicators from './stepIndicators';

import { InputField } from '../InputField';
import * as CountryList from 'country-list-with-dial-code-and-flag';
import { handleKeyPressNumber } from '../../_common/functions';
import Select from '../blocks/Select';
import ToggleSwitch from '../blocks/toggleSwitch';
import { SelectOption } from '../../_common/types';
import { OTHER_RELATIONSHIP_CODE } from '../../_common/constants';
import { ApiSearchBox } from '../ApiSearchbox';
import { ACTIONS, API_URL } from '../../_config';
import { StateExtended } from '../../_common/interfaces/StateExtended';
interface incomingProps {
    onSubmitAddressForm?: any;
    tab?: any
}

export default function Step3({ onSubmitAddressForm, tab }: incomingProps) {
    const signupState = useSelector((state: StateExtended) => state.signup)
    const dispatch = useDispatch()

    const { register, handleSubmit, formState: { errors }, control, watch, reset } = useForm()
    const country = watch('CountryId');
    let ProfileId: any = sessionStorage.getItem("ProfileId")

    const commonApi = useCommonApi()
    const navigate = useNavigate()

    const [hospitalizationToggle, setHospitalizationToggle] = useState<boolean>(false)
    const [deathToggle, setDeathToggle] = useState<boolean>(false)
    const [incarcerationToggle, setIncarcerationToggle] = useState<boolean>(false)
    const [relationship, setRelationship] = useState<SelectOption[]>([])
    const [countries, setCountries] = useState<SelectOption[]>([])
    const [dialCode, setDialCode] = useState<SelectOption[]>([])
    const [profileId, setProfileId] = useState<any>(null)

    const relationshipWatch = watch('RelationshipType')

    const goBack = () => {
        navigate(-1);
    }

    const onSubmit = (data: any) => {
        let params = {
            FirstName: data.FirstName,
            LastName: data.LastName,
            Email: data.Email,
            DialCode: data.DialCode,
            CellPhone: data.CellPhone,
            CountryId: data.CountryId,
            CityId: data.CityId,
            RelationshipType: parseInt(data.RelationshipType, 10),
            Address: data.Address,
            Hospitalization: hospitalizationToggle,
            Death: deathToggle,
            Incarceration: incarcerationToggle,
            OtherRelationship: data.OtherRelationship || ''
        }

        dispatch({
            type: ACTIONS.SIGNUP.CREATE_STEP3,
            payload: params
        })
        navigate('/signup?step=4')
    }

    const retrieveRelationShip = () => {
        commonApi.retrieveRelationShip({ "DropdownName": "xql_relationshiotype" },
            (message: string, resp: any) => {
                let options: any = [];
                if (resp.Options && resp.Options.length > 0) {
                    resp.Options.map((item: any) => {
                        item.value = item.Value
                        item.label = item.Label.UserLocalizedLabel.Label
                        options.push(item)
                    })
                    setRelationship(options)
                }
            }, (message: string) => {
                console.log(message);
            })
    }

    const getCountries = () => {
        commonApi.getCountries({},
            (message: string, resp: any) => {
                if (resp.length > 0) {
                    setCountries(resp.map((data: any) => {
                        data.label = data.xql_name;
                        data.value = data.xql_countryid;
                        return data;
                    }))
                }
            }, (message: string) => {
                console.log(message);
            })
    }


    const getDialCode = (data: any) => {
        if (data.length > 0) {
            setDialCode(data.map((item: any) => {
                item.label = `(${item.dial_code}) ${item.name}`;
                item.value = item.dial_code;
                return item;
            }).sort((a: SelectOption, b: SelectOption) => Number(a.value) - Number(b.value))
            )
        }
    }

    const dialCodeOptionUI = (props: any) => {
        const { innerProps, innerRef, data } = props;
        return (
            <div ref={innerRef} {...innerProps} className="dial-code-option">
                {data.flag}
                <span>{data.dial_code}</span>
            </div>
        );
    }

    useEffect(() => {
        getCountries()
        retrieveRelationShip()
        getDialCode(CountryList.getList())
        if (signupState.NextOfKins && signupState.NextOfKins.length !== 0) {
            reset({
                FirstName: signupState.NextOfKins[0]?.FirstName,
                LastName: signupState.NextOfKins[0]?.LastName,
                Email: signupState.NextOfKins[0]?.Email,
                DialCode: signupState.NextOfKins[0]?.DialCode,
                CellPhone: signupState.NextOfKins[0]?.CellPhone,
                CountryId: signupState.NextOfKins[0]?.CountryId,
                CityId: signupState.NextOfKins[0]?.CityId,
                RelationshipType: signupState.NextOfKins[0]?.RelationshipType,
                Address: signupState.NextOfKins[0]?.Address,
                OtherRelationship: signupState.NextOfKins[0]?.OtherRelationship
            })
        }
        if (signupState.NextOfKins.length !== 0) {
            setIncarcerationToggle(signupState.NextOfKins[0].Incarceration)
            setDeathToggle(signupState.NextOfKins[0].Death)
            setHospitalizationToggle(signupState.NextOfKins[0].Hospitalization)
        }
    }, [])

    useEffect(() => {
        console.log({ ProfileId })
        if (ProfileId == 'undefined') {
            setProfileId(null)
        } else {
            setProfileId(JSON.parse(ProfileId))
        }
    }, [ProfileId])


    return (
        <React.Fragment>

            <h1>Create Profile</h1>
            <StepIndicators step={3} />
            <div className="d-flex justify-content-between mt-5">
                <h2>Contact details of next of kin</h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="w-100" autoComplete='off'>
                <div className="container-fluid p-0">
                    <Row>
                        <Col sm={6}>
                            <fieldset className={"input" + (errors?.FirstName ? ' invalid' : '')}>
                                <legend>First name</legend>
                                <input type="text" className="" placeholder='' {...register("FirstName", { required: true, maxLength: 80 })} />
                            </fieldset>
                        </Col>
                        <Col sm={6}>
                            <fieldset className={"input" + (errors?.LastName ? ' invalid' : '')}>
                                <legend>Last name</legend>
                                <input type="text" className="" {...register("LastName", { required: true, maxLength: 80 })} />
                            </fieldset>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <fieldset className={"input" + (errors?.CountryId ? ' invalid' : '')}>
                                <legend>Country</legend>
                                <Controller
                                    name={"CountryId"}
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue=""
                                    render={({ field: { value, onChange, onBlur } }) => {
                                        return (<Select
                                            className={"select-box"}
                                            options={countries ? countries : []}
                                            placeholder={""}
                                            onChange={onChange}
                                            value={value}
                                            defaultValue={value}
                                        />)
                                    }}
                                />
                            </fieldset>
                        </Col>
                        <Col sm={6}>
                            <fieldset className={"input" + (errors?.CityId ? ' invalid' : '')}>
                                <legend>City</legend>
                                <Controller
                                    name={'CityId'}
                                    control={control}
                                    rules={{ required: true }}
                                    defaultValue=""
                                    render={({ field: { value, onChange, ...restOfFieldProps } }) => {
                                        return (
                                            <ApiSearchBox
                                                onChange={onChange}
                                                value={value}
                                                defaultValue={value}
                                                {...restOfFieldProps}
                                                endpointUrl={API_URL.COMMON.GET_CITIES}
                                                endpointMethod="POST"
                                                body={{
                                                    CountryId: country,
                                                    Name: '{{value}}',
                                                }}
                                                parserFunction={(data) => {
                                                    return (data as Record<string, string>[]).map(
                                                        (el) => {
                                                            return {
                                                                label: el.xql_name,
                                                                value: el.xql_cityid,
                                                            };
                                                        }
                                                    );
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </fieldset>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <fieldset
                                className={
                                    'input' +
                                    (errors?.RelationshipType ? ' invalid' : '')
                                }
                            >
                                <legend>Relationship</legend>
                                <Controller
                                    name={'RelationshipType'}
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange, onBlur } }) => {
                                        return (
                                            <Select
                                                className={'select-box'}
                                                options={relationship ? relationship : []}
                                                placeholder={''}
                                                onChange={onChange}
                                                value={value}
                                                defaultValue={value}
                                            />
                                        );
                                    }}
                                />
                            </fieldset>
                            {
                                relationshipWatch === OTHER_RELATIONSHIP_CODE &&
                                <InputField id='OtherRelationship' required={true} label='How are you related?' register={register} errors={errors} />
                            }
                        </Col>
                        <Col sm={6}>
                            <fieldset className={"input" + (errors?.Address ? ' invalid' : '')}>
                                <legend>Address</legend>
                                <input type="text" className="" {...register("Address", { required: true, maxLength: 80 })} />
                            </fieldset>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <fieldset className={"input" + (errors?.Email ? ' invalid' : '')}>
                                <legend>Email</legend>
                                <input type="email" className="" placeholder='' {...register("Email", { required: false, maxLength: 80, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                            </fieldset>
                            {errors?.Email ? <div className="invalid text-danger">
                                {errors.Email.type === "required" ? 'Email Required' : null}
                                {errors.Email.type === "pattern" ? 'Invalid email address' : null}
                            </div> : null}
                        </Col>
                        <Col sm={6}>
                            <Row>
                                <Col sm={5}>
                                    <fieldset className={"input" + (errors?.DialCode ? ' invalid' : '')}>
                                        <legend>Code</legend>
                                        <Controller
                                            name={"DialCode"}
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field: { value, onChange, onBlur } }) => {
                                                return (<Select
                                                    components={{ Option: dialCodeOptionUI }}
                                                    className={"select-box"}
                                                    options={dialCode ? dialCode : []}
                                                    placeholder={""}
                                                    onChange={onChange}
                                                    value={value}
                                                    defaultValue={value}
                                                />)
                                            }}
                                        />
                                    </fieldset>
                                </Col>
                                <Col sm={7}>
                                    <fieldset className={"input" + (errors?.CellPhone ? ' invalid' : '')}>
                                        <legend>Phone number</legend>
                                        <input type="text" className="" {...register("CellPhone", { required: true, maxLength: 15 })} onKeyPress={(event: any) => handleKeyPressNumber(event)} />
                                    </fieldset>
                                    {errors?.CellPhone ? <div className="invalid text-danger">
                                        {errors.CellPhone.type === "required" ? 'Phone Number is Required' : null}
                                        {errors.CellPhone.type === "maxLength" ? 'Phone Number should be of 10 digit' : null}
                                    </div> : null}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div>
                    <h6>I authorise DIRCO to contact designated next of kin, in any or all of the following cases (please select):</h6>
                </div>
                <div className="d-flex justify-content-around align-items-center gap-3 toggle-btns">

                    <div className="form-check form-switch d-flex align-items-center">
                        <label className="form-check-label mb-0 pe-5 me-3" htmlFor="flexSwitchCheckChecked">Hospitalisation</label>
                        <ToggleSwitch condition={hospitalizationToggle} setCondition={setHospitalizationToggle} />
                        {/* <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={() => setHospitalizationToggle(!hospitalizationToggle)} /> */}

                    </div>
                    <div className="form-check form-switch d-flex align-items-center">
                        <label className="form-check-label mb-0 pe-5 me-3" htmlFor="flexSwitchCheckCheckedTwo">Death</label>
                        <ToggleSwitch condition={deathToggle} setCondition={setDeathToggle} />
                        {/* <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedTwo" onChange={() => setDeathToggle(!deathToggle)} /> */}

                    </div>
                    <div className="form-check form-switch d-flex align-items-center">
                        <label className="form-check-label mb-0 pe-5 me-3" htmlFor="flexSwitchCheckCheckedThree">Incarceration</label>
                        <ToggleSwitch condition={incarcerationToggle} setCondition={setIncarcerationToggle} />
                        {/* <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedThree" onChange={() => setIncarcerationToggle(!incarcerationToggle)} /> */}

                    </div>
                </div>
                <div className="mt-2 w-100 login-btns d-flex gap-2">
                    <Button variant='success' type='submit' className="btn btn-success w-100">Accept</Button>
                    <Button onClick={() => goBack()} className="btn btn-transparent w-100" variant="secondary">Back</Button>
                </div>
            </form>
        </React.Fragment>
    )
}